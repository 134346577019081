import React, { useState, useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { connect } from 'react-redux'
import mixpanel from 'mixpanel-browser'
import PropTypes from 'prop-types'

import ResultWrapper from './results/ResultWrapper'
import { setProduct } from 'redux/actions/product'
import { setCountry } from 'redux/actions/userLocation'
import { setReference } from 'redux/actions/reference'
import ErrorView from 'components/shared/ErrorView'

const propTypes = {
  reference: PropTypes.object,
  theme: PropTypes.object,
  product: PropTypes.object,
  userLocation: PropTypes.object,
  setProduct: PropTypes.func,
  setCountry: PropTypes.func,
  setReference: PropTypes.func
}

const ResultView = ({
  reference,
  theme,
  product,
  userLocation,
  setProduct,
  setCountry,
  setReference
}) => {
  const [error, setError] = useState(false)
  const searchParams = new URLSearchParams(location.search)
  const { product_id: productId } = useParams()
  const referenceId = searchParams.get('reference')
  const lotNumber = searchParams.get('lot')

  useEffect(() => {
    const fetchReference = async() => {
      try {
        const url = lotNumber
          ? `/api/products/${productId}?reference=${referenceId}&lot=${lotNumber}`
          : `/api/products/${productId}?reference=${referenceId}`
        const { data } = await axios.get(url)
        setReference({ ...data.reference, lot: lotNumber })
        setProduct(data)
      } catch (err) {
        console.log(err)
        setError(true)
      }
    }

    const fetchCountry = async () => {
      try {
        const { data } = await axios.get('/api/country_location.json')
        setCountry(data)
      } catch (err) {
        console.log(err)
        setError(true)
      }
    }

    fetchReference()
    fetchCountry()
  }, [productId, lotNumber, setCountry, setReference, setProduct])

  useEffect(() => {
    mixpanel.track('ResultView', { reference })
  }, [reference])

  const LoadingView = () => (
    <div
      data-testid='LoadingView'
      className='d-flex align-items-center justify-content-center flex-grow-1'
    >
      <Spinner animation='border' role='status'>
        <span className='sr-only'>Loading...</span>
      </Spinner>
    </div>
  )

  const DisplayView = () => {
    if (reference && product && userLocation) {
      return (
        <ResultWrapper
          reference={reference}
          product={product}
          userLocation={userLocation}
        />
      )
    } else if (error) {
      return <ErrorView reference={reference} />
    } else {
      return <LoadingView />
    }
  }

  return <DisplayView />
}

ResultView.propTypes = propTypes

const mapStateToProps = ({ reference, product, userLocation }) => ({
  reference,
  product,
  userLocation
})

export default connect(mapStateToProps, {
  setProduct,
  setCountry,
  setReference
})(ResultView)
