import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Spinner, Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Cookies } from 'react-cookie'

// import './helpers/i18n'
import ConnectHeader from './ConnectHeader'
import ConnectFooter from './ConnectFooter'
import Disclaimer from './Disclaimer'
import CookieStatement from './CookieStatement'
import ResultView from './ResultView'
import FindSection from './FindSection'
import OrderView from './OrderView'
import DocumentView from './DocumentView'
import { setTheme } from 'redux/actions/theme'
import HeaderTags from './HeaderTags'
import DemoDisclaimer from './DemoDisclaimer'
import RegionView from './RegionView'
import NoMatchView from './NoMatchView'
import I18nEnhancer from './I18nEnhancer'
import { setOrganisation } from 'redux/actions/organisation'
import {
  HCP_COOKIE_NAME,
  SELECTED_COUNTRY_CODE_COOKIE_NAME,
  SUPPORTED_LANGUAGES
} from 'components/connect/helpers/Constants'
import InfoModal from './InfoModal'

const propTypes = {
  theme: PropTypes.object,
  setTheme: PropTypes.func,
  organisation: PropTypes.object,
  setOrganisation: PropTypes.func
}

const computeBackgroundStyle = ({ theme }) => {
  const bgColor = theme.layout.background_color;
  const bgImageUrl = theme.background_image.url;

  return `${bgColor} url('${bgImageUrl}') no-repeat center center`
}

const StyledMainView = styled.div`
  background: ${(props) => computeBackgroundStyle(props)};
  background-size: cover;
`

const ConnectApp = ({ theme, setTheme, organisation, setOrganisation }) => {
  const [error, setError] = useState(false)
  const [metadata, setMetadata] = useState(false)
  const [translations, setTranslations] = useState(null)
  const [supportedLanguages, setSupportedLanguages] = useState(
    SUPPORTED_LANGUAGES
  )
  const [infoModal, setInfoModal] = useState(false)
  const [showDisclaimer, setShowDisclaimer] = useState(
    document.cookie
      .split(';')
      .map((s) => s.trim())
      .filter((s) => s.startsWith(HCP_COOKIE_NAME)).length <= 0
  )

  const openInfoModal = () => setInfoModal(true)
  const closeInfoModal = () => setInfoModal(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: theme } = await axios.get('/api/themes.json')
        setTheme(theme)
        const { data: organisation } = await axios.get(
          `/api/organisations/${theme.organisation_id}.json`
        )
        setOrganisation(organisation)
        const { data: metadata } = await axios.get(
          `/api/themes/${theme.id}/metadata.json`
        )
        setMetadata(metadata)
        const { data: translationsPath } = await axios.get(
          `/api/themes/${theme.id}/translations_pre_signed_url`
        )
        if (translationsPath?.path) {
          const { data: translationsFile } = await axios.get(
            translationsPath.path
          )
          setTranslations(Object.keys(translationsFile))
        }
      } catch (err) {
        setError(true)
      }
    }

    fetchData()
  }, [setMetadata, setError, setOrganisation, setTheme, setTranslations])

  useEffect(() => {
    if (translations) {
      const supportedLanguagesUnion = SUPPORTED_LANGUAGES.filter((language) =>
        translations.includes(language)
      )
      setSupportedLanguages(supportedLanguagesUnion)
    }
  }, [translations?.length])

  const MainView = () => (
    <Router>
      {!showDisclaimer ? (
        <StyledMainView
          data-testid='MainView'
          className='min-vh-100 d-flex flex-column main-view'
          theme={theme}
        >
          <HeaderTags theme={theme} />
          <ConnectHeader
            theme={theme}
            languages={supportedLanguages}
            openInfoModal={openInfoModal}
          />
          <DemoDisclaimer organisation={organisation} />
          <Switch>
            <Route
              exact
              path='/'
              render={(props) => <FindSection {...props} theme={theme} />}
            />
            <Route
              path='/order/:document/:version/'
              render={(props) => <OrderView {...props} theme={theme} />}
            />
            <Route
              path='/document/:document/:version'
              render={(props) => <DocumentView {...props} theme={theme} />}
            />
            <Route
              path='/products/:product_id'
              render={(props) => <ResultView {...props} theme={theme} />}
            />
            <Route
              path='*'
              render={(props) => <NoMatchView {...props} theme={theme} />}
            />
          </Switch>
          <ConnectFooter theme={theme} metadata={metadata} />
          <CookieStatement theme={theme} />
          <InfoModal
            theme={theme}
            show={infoModal}
            handleClose={closeInfoModal}
          />
        </StyledMainView>
      ) : (
        <Disclaimer
          theme={theme}
          show={showDisclaimer}
          setShow={setShowDisclaimer}
        />
      )}
    </Router>
  )

  const RegionContainer = ({ languages }) => (
    <Router>
      <RegionView
        theme={theme}
        languages={languages}
        infoModal={infoModal}
        openInfoModal={openInfoModal}
        closeInfoModal={closeInfoModal}
      />
    </Router>
  )

  RegionContainer.propTypes = {
    languages: PropTypes.array
  }

  const ErrorView = () => (
    <div
      data-testid='ErrorView'
      className='d-flex align-items-center justify-content-center vh-100'
    >
      <Row>
        <Col>
          <h1>Oops!</h1>
          <p>This domain does not seem to be connected to our service.</p>
          <p>Contact our support center:</p>
          <p>support@dokspot.com</p>
        </Col>
      </Row>
    </div>
  )

  const LoadingView = () => (
    <div
      data-testid='LoadingView'
      className='d-flex align-items-center justify-content-center vh-100'
    >
      <Spinner animation='border' role='status'>
        <span className='sr-only'>Loading...</span>
      </Spinner>
    </div>
  )

  const DisplayView = () => {
    const cookies = new Cookies()
    const cookieValue = cookies.get(SELECTED_COUNTRY_CODE_COOKIE_NAME)
    const countryCode = cookieValue && cookieValue !== 'null'
    const isAllDataFetched =
      theme && organisation && metadata && supportedLanguages

    if (isAllDataFetched) {
      if (theme.country_selection && !countryCode) {
        return <RegionContainer languages={supportedLanguages} />
      } else {
        return <MainView />
      }
    } else if (error) {
      return <ErrorView />
    } else {
      return <LoadingView />
    }
  }

  return (
    <I18nEnhancer theme={theme}>
      <DisplayView />
    </I18nEnhancer>
  )
}

ConnectApp.propTypes = propTypes

const mapStateToProps = ({ theme, organisation }) => ({ theme, organisation })

export default connect(mapStateToProps, { setTheme, setOrganisation })(
  ConnectApp
)
